import { trpc } from "../../../lib/trpc";
import { getCountryCodeAndNumber } from "../../../utils/countryCodeAndNumber";
import DeleteSvg from "/assets/delete.svg";
import RefreshSvg from "/assets/refresh.svg";

export default function Profile() {
  const user = trpc.user.getUserById.useQuery({});
  const { data: auth } = trpc.user.getAccountType.useQuery({});

  return (
    <div className="grid w-full grid-cols-2 bg-white gap-x-8">
      {/* Business Information Section */}
      {auth?.acountType === "CORPORATE" && (
        <div className="shadow-s-semi-light rounded-2xl mt-4 border-[1.8px] border-hgray-100 bg-hgray-50 py-6 px-8 hover:scale-95 hover:bg-hbrand-50">
          {" "}
          <h2 className="text-[17.5px] font-satoshi font-bold text-hgray-600 mb-9">
            Business information
          </h2>
          <div className="flex justify-between mb-3">
            <label className="block mb-1 font-satoshi text-[14.5px] text-hgray-500 font-medium">
              Organisation
            </label>
            <p className="font-satoshi text-[15.5px] font-medium text-hgray-800">Edu Frames</p>
          </div>
          <hr className="h-px my-4 bg-hgray-200 border-0" />
          <div className="flex justify-between mb-3">
            <label className="block mb-1 font-satoshi text-[14.5px] text-hgray-500 font-medium">
              Your role
            </label>
            <p className="font-satoshi text-[15.5px] font-medium text-hgray-800">Admin</p>
          </div>
          <hr className="h-px my-4 bg-gray-200 border-0" />
          <div className="flex justify-between mb-3">
            <label className="block mb-1 font-satoshi text-[14.5px] text-hgray-500 font-medium">
              Business Logo
            </label>
            <div className="flex items-center justify-center w-12 h-12 text-[17.5px] font-satoshi font-medium text-hgray-600 bg-hgray-300 rounded-full">
              E
            </div>{" "}
          </div>
          <div className="flex justify-end mt-16 ">
            <div className="flex gap-x-4 text-end">
              <button className="flex items-center justify-center px-3 py-1 text-[13px] font-inter font-semibold bg-transparent border-none rounded cursor-pointer text-herror-700 hover:text-herror-600">
                <img src={DeleteSvg} alt="" className="w-4 h-4 mr-2" />
                Remove
              </button>
              <button className="flex items-center justify-center px-3 py-2 text-[13px] font-inter font-semibold bg-transparent border rounded-lg cursor-pointer gap-x-2 border-hbrand-600 text-hbrand-600 hover:border-hbrand-700 hover:text-hbrand-700">
                <img src={RefreshSvg} alt="" />
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Personal Information Section */}
      <div className="shadow-s-semi-light rounded-2xl mt-4 border-[1.8px] border-hgray-100 bg-hgray-50 py-6 px-8 hover:scale-95 hover:bg-hbrand-50">
        {" "}
        <h2 className="text-[17.5px] font-satoshi font-bold text-hgray-600 mb-9">
          Personal information
        </h2>
        <div className="flex justify-between mb-3">
          <label className="block mb-1 font-satoshi text-[14.5px] text-hgray-500 font-medium">Name</label>
          <p className="font-satoshi text-[15.5px] font-medium text-hgray-800">{`${user.data?.firstName} ${user.data?.lastName}`}</p>
        </div>
        <hr className="h-px my-4 bg-hgray-200 border-0" />
        <div className="flex justify-between mb-3">
          <label className="block mb-1 font-satoshi text-[14.5px] text-hgray-500 font-medium">
            Phone number
          </label>
          <p className="font-satoshi text-[15.5px] font-medium text-hgray-800">
            <span className="inline-flex items-center">
              <svg
                className="w-4 h-4 mr-1 text-green-600"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 1a9 9 0 110 18A9 9 0 0110 1zm0 2a7 7 0 100 14A7 7 0 0010 3zm0 11a1 1 0 110 2 1 1 0 010-2zm1-7V7a1 1 0 00-2 0v3a1 1 0 00.293.707l2 2A1 1 0 0012 12a1 1 0 00-1-1H9V9h2z"></path>
              </svg>
              ({" "}
              {
                getCountryCodeAndNumber(user.data?.phoneNumber || "")
                  .countryCode
              }
              ){" "}
              {
                getCountryCodeAndNumber(user.data?.phoneNumber || "")
                  .nationalNumber
              }
            </span>
          </p>
        </div>
        <hr className="h-px my-4 bg-hgray-200 border-0" />
        <div className="flex justify-between mb-3">
          <label className="block mb-1 font-satoshi text-[14.5px] text-hgray-500 font-medium">Email</label>
          <p className="font-satoshi text-[15.5px] font-medium text-hgray-800">
            {user.data?.email || ""}{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

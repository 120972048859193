import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { trpc } from "../../../lib/trpc";
import RadioButton from "../../Common/buttons/radioBtn";

type AccountTypes = "CORPORATE" | "INDIVIDUAL" | "TEAM_MEMBER";
const AccountType: FC = () => {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  const { mutate, isLoading: isSettingAccount } =
    trpc.user.setAccountType.useMutation({
      onSuccess: () => {
        handleNavigate(`/dashboard/get-started/${selectedValue}`);
      },
      onError: () => {
        toast.error("Try again!");
      },
    });

  const { data } = trpc.user.getAccountType.useQuery({});

  useEffect(() => {
    data?.acountType && setSelectedValue(data.acountType);
  }, [data]);

  const [selectedValue, setSelectedValue] = useState<AccountTypes>();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value as AccountTypes);
    setIsDisabled(false);
  };

  const handleNavigate = (route: string) => {
    navigate(route); // Replace '/new-route' with your desired route
  };

  return (
    <div className="login relative h-screen w-screen bg-white">
      <div className="login-content flex h-full w-full flex-col items-start overflow-hidden">
        <section className="content-top flex w-full items-center justify-between px-16 pb-8 pt-10">
          <div className="w-[120px]">
            <img className="h-full w-full" src="/logo.jpeg" alt="Shiga Logo" />
          </div>
        </section>

        <section className="mt-[5px] content-center justify-center self-center justify-self-center rounded-lg bg-white px-[30px] pb-[25px] pt-[5px]">
          <div className="content-header">
            <h4 className="mb-[10px] text-center text-[32px] font-semibold tracking-[-0.72px] text-htext-main">
              How are you using Shiga?
            </h4>
            <p className="text-center text-[14px] font-normal text-htext-subdued">
              We'll streamline your setup experience accordingly.
            </p>
          </div>

          <div className="content-cards mt-[40px] flex w-[510px] items-center justify-between">
            <div
              className={`flex h-[315px] w-[240px] flex-col rounded-[8px]  ${
                selectedValue === "INDIVIDUAL"
                  ? "border-2 border-hbrand-700"
                  : "border border-hgray-200"
              } bg-white px-[24px] pb-[42px] pt-[24px]`}
            >
              <RadioButton
                name="account-type"
                value="INDIVIDUAL"
                checked={selectedValue === "INDIVIDUAL"}
                onChange={handleRadioChange}
                className="accs-radio w-auto self-end"
              />

              <div className="card-content flex flex-grow flex-col items-center">
                <div className="card-img mt-[13px] w-[155px]">
                  <img
                    className="w-full"
                    src="/assets/individual.svg"
                    alt="individual icon"
                  />
                </div>

                <div className="card-text mt-[16px]">
                  <h5 className="text-center text-base font-semibold text-htext-main">
                    As an Individual
                  </h5>
                  <p className="mt-[6px] text-center text-[13px] font-normal text-htext-subdued">
                    An individual account to exchange, buy, sell, transfer
                    multichain stablecoins for personal use
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`flex h-[315px] w-[240px] flex-col rounded-[8px]  ${
                selectedValue === "CORPORATE"
                  ? "border-2 border-hbrand-700"
                  : "border border-hgray-200"
              } bg-white px-[24px] pb-[42px] pt-[24px]`}
            >
              <RadioButton
                name="account-type"
                value="CORPORATE"
                checked={selectedValue === "CORPORATE"}
                onChange={handleRadioChange}
                className="accs-radio w-auto self-end"
              />

              <div className="card-content flex flex-grow flex-col items-center">
                <div className="card-img mt-[13px] w-[155px]">
                  <img
                    className="w-full"
                    src="/assets/corporate.svg"
                    alt="business icon"
                  />
                </div>

                <div className="card-text mt-[16px]">
                  <h5 className="text-center text-base font-semibold text-htext-main">
                    As a Business
                  </h5>
                  <p className="mt-[6px] text-center text-[13px] font-normal text-htext-subdued">
                    For business who want to create teams, exchange, buy, sell,
                    transfer multichain stablecoins
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="font-ibm-plex mt-[5px] w-full font-normal antialiased">
            <button
              className={`mb-[16px] mt-[50px] w-full rounded-lg border border-solid  px-[10px] py-[11px] text-[15px]    ${
                isDisabled
                  ? "border-hgray-200 bg-hgray-100 font-semibold text-hgray-400"
                  : "border-brand-600 bg-brand-600 font-bold text-white hover:bg-hbrand-700"
              }`}
              type="submit"
              onClick={() => {
                selectedValue && mutate({ setAccountType: selectedValue });
              }}
              disabled={isDisabled}
            >
              {isSettingAccount ? (
                <span className="loading loading-spinner loading-sm"></span>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AccountType;
